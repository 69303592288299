"use client";

import "../styles/colors.css";
import "../styles/fonts.css";
import "../styles/composition.css";
import "../styles/core.css";
import "../styles/blocks.css";
import "../styles/globals.css";
import "../styles/utility.css";
// new styles that are being tested out
import "../styles/styles2.scss";

/**
import '../styles/core/button.css'
import '../styles/core/dropdown.css'
import '../styles/core/input.css'
/**/
import "../styles/core/details.css";
import "../styles/core/breadcrumbs.css";
import "../styles/core/checkbox.css";
import "../styles/core/radio.css";
import { Inter, Roboto_Mono } from "next/font/google";
/**/
import "../styles/colors2.scss";
import "../styles/core/button2.scss";
import "../styles/core/select2.scss";
import "../styles/core/input2.scss";
/**/

import Script from "next/script";
import { ReduxProvider } from "../redux/provider";
import dynamic from "next/dynamic";
import { CSPostHogProvider } from "./providers";
import { ItemProvider } from "../context/ItemContext";

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-inter",
});

const roboto_mono = Roboto_Mono({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto-mono",
});
const metadata = {
  metadataBase: new URL("https://sellraze.com/"),
//   title: {
//     default: "SellRaze | The fastest way to sell your stuff.",
//     template: "%s | SellRaze",
//   },
//   description:
//     "The #1 Cross Listing app that identifies, prices, and lists items in seconds. Simply take images of your items, and select your favorite marketplaces.",
//   keywords: [
//     "free cross listing app",
//     "poshmark",
//     "cross listing app",
//     "how to sell on ebay",
//     "craigslist",
//     "ebay",
//     "mercari",
//   ],
//   robots: {
//     index: true,
//     follow: true,
//     nocache: true,
//     googleBot: {
//       index: true,
//       follow: true,
//     },
//   },
  twitter: {
    card: "summary_large_image",
    site: "@SellRaze",
    creator: "@SellRaze",
    images: "https://sellraze.com/assets/sellraze_embed.png",
    title: {
      default: "Sellraze: The future of selling online is here.",
      template: "%s | SellRaze",
    },
    description:
      "Create listings in seconds, and track inventory across your favorite marketplaces, all in one place.",
  },
  openGraph: {
    url: "https://sellraze.com/",
    type: "website",
    locale: "en_US",
    siteName: "SellRaze",
    title: {
      default: "SellRaze: The future of selling online is here.",
      template: "%s | SellRaze",
    },
    description:
      "Turn an image into a listing in seconds using the SellRaze app. Crosslist across your favorite marketplaces.",
    images: [
      {
        url: "https://sellraze.com/assets/sellraze_embed.png",
        alt: "SellRaze: The future of selling online is here.",
      },
    ],
  },
};

const PostHogPageView = dynamic(() => import("./PostHogPageView"), {
  ssr: false,
});

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className={`${inter.variable} ${roboto_mono.variable}`}>
      <head>
        <meta property="og:image" content="https://sellraze.com/assets/sellraze_embed.png" />
        <meta name="twitter:image" content="https://sellraze.com/assets/sellraze_embed.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <Script id="intercom-settings">
          {`
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "a77qsylr",
          };
        `}
        </Script>
        <Script id="intercom-widget" strategy="lazyOnload">
          {`
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/a77qsylr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
        </Script>
        <Script id="facebook-pixel" strategy="afterInteractive">
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '146524518548459');
        fbq('track', 'PageView'); 
      `}
        </Script>
      </head>
      <body>
        <div />
        <ItemProvider>
          {/* <PostHogPageView /> */}
          <CSPostHogProvider>
            <ReduxProvider>{children}</ReduxProvider>
          </CSPostHogProvider>
        </ItemProvider>
        {/*
          have to inline the linear gradient because only firefox
          lets you use external svg gradients as a css fill value
        */}
        <svg style={{ height: 0, width: 0, display: "block" }} aria-hidden="true">
          <defs>
            <linearGradient id="blue_gradient" x1="0" y1="0" x2="100%" y2="100%">
              <stop offset="-20%" stopColor="white" />
              <stop offset="60%" stopColor="var(--blue-gradient-start)" />
              <stop offset="120%" stopColor="var(--blue-gradient-stop)" />
            </linearGradient>
            <linearGradient id="loading_gradient">
              <stop stopColor="var(--dark-32)" offset="30%" />
              <stop stopColor="var(--dark-16)" offset="50%" />
              <stop stopColor="var(--dark-32)" offset="70%" />
            </linearGradient>
          </defs>
        </svg>
      </body>
    </html>
  );
}
