import React, { createContext, useState, useContext, useCallback, useEffect, Suspense } from 'react';
import { useSearchParams } from 'next/navigation';

type ItemContextType = {
  addItemOpen: boolean;
  setAddItemOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemDetailsOpen: boolean;
  setItemDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settingsOpen: boolean;
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSettingsOption: number;
  setSelectedSettingsOption: React.Dispatch<React.SetStateAction<number>>;
}

const ItemContext = createContext<ItemContextType | undefined>(undefined);

const ItemProviderContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [addItemOpen, setAddItemOpen] = useState<boolean>(false);
  const [itemDetailsOpen, setItemDetailsOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [selectedSettingsOption, setSelectedSettingsOption] = useState<number>(10);

  return (
    <ItemContext.Provider 
      value={{ 
        addItemOpen, 
        setAddItemOpen, 
        itemDetailsOpen, 
        setItemDetailsOpen, 
        settingsOpen,
        setSettingsOpen,
        selectedSettingsOption,
        setSelectedSettingsOption
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};


export const ItemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <ItemProviderContent>{children}</ItemProviderContent>
  </Suspense>
);

export const useItemContext = () => {
  const context = useContext(ItemContext);
  if (context === undefined) {
    throw new Error('addItemOpen must be used within an AddItemProvider');
  }
  return context;
};